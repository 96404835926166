import React from 'react';
import { graphql } from "gatsby"
import SEO from '../components/seo';
import NotFound from '../containers/not-found';
import Layout from "../components/layout";


interface NotFoundProps {
  data?: any
}


const NotFoundPage: React.FunctionComponent<NotFoundProps> = ({ data } ) => {
  const { image, featuredImage, site } = data
  return (
    <Layout>
      <SEO
          title="404: Not Found"
          image={featuredImage ? featuredImage.publicURL : site.siteMetadata.image}
      />
      <NotFound image={image}/>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    image: file(absolutePath: { regex: "/404.svg/" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    featuredImage: file(absolutePath: { regex: "/404.png/" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    site {
      siteMetadata {
        title
        image
        siteUrl
      }
    }
  }
`;
