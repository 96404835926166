import * as React from 'react';
import GatsbyImage from '../../components/gatsby-image';
import SVGImage from "../../components/svg-image/svg-image";
import { Link } from 'gatsby';
import { IoMdArrowRoundBack } from 'react-icons/io';
import {
  NotFoundWrapper,
  NotFoundContent,
  NotFoundImage,
  Goback,
  Icon,
} from './style';

interface NotFoundProps {
  image?: any
}

const NotFound: React.FunctionComponent<NotFoundProps> = ( { image } ) => {
  return (
    <NotFoundWrapper>
      <NotFoundContent>
        <h1>This page was lost, or you are</h1>
        <p>
          Unfortunately, the page you tried to visit is unavailable.
          Use the Go Back button below or return to the homepage.
        </p>
        <Goback>
          <Link to="/">
            <Icon>
              <IoMdArrowRoundBack className={"transition-join"}/>
            </Icon>
            Go Back
          </Link>
        </Goback>
      </NotFoundContent>
      {image ? (
        <NotFoundImage style={{maxWidth: "400px"}}>
            {image.publicURL.endsWith('.svg') ? (
              <SVGImage
                src={image.publicURL}
                alt={"not found"}
              />
            ) : (
              <GatsbyImage
                  src={image.childImageSharp.gatsbyImageData}
                  alt={"not found"}
              />
            )}
        </NotFoundImage>
      ) : null}
    </NotFoundWrapper>
  );
};

export default NotFound;
